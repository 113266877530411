import React from 'react';
import { Link } from 'gatsby';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import Heading from '@/atoms/Heading';
import Head from '@/components/Head';
import Footer from '@/components/Footer';
import PageContainer from '@/components/PageContainer';

import theme from '@/styles/theme';
import WithArrow from '../atoms/WithArrow';

export default () => {
  return (
    <PageContainer>
      <Head title="(404) Page not found" meta={{ robotsIndex: false }} />

      <Grid.Container mb={theme.spacing.large}>
        <Grid>
          <Grid.Item w={[10 / 12, 10 / 12, 1 / 2]}>
            <Heading h={2} as="h1" mb={3}>
              404
            </Heading>

            <Text t={1} $color="gray" mb={theme.spacing.lhPL}>
              Page not found.
              <br />
              Please use the menu at the top of the page to access the content
              you are looking for or visit the front page.
            </Text>

            <Text t={1}>
              <Link to="/">
                <WithArrow>Visit Frontpage</WithArrow>
              </Link>
            </Text>
          </Grid.Item>
        </Grid>
      </Grid.Container>

      <Footer />
    </PageContainer>
  );
};
